<div class="navbar-container">
    <mat-toolbar class="toolbar-container" color="primary">
        <div class="toolbar-left">
            <!-- <button mat-icon-button class="meu-icon" (click)="clickMenu()">
                <mat-icon>menu</mat-icon>
            </button> -->
            <a class="toolbar-logo" [routerLink]="['/']">
                <img class="toolbar-logo-image" src="../assets/jera.svg">
            </a>
            <h1>
                {{applicationName}}
            </h1>
        </div>

        <div class="toolbar-right">
            <button mat-icon-button [matMenuTriggerFor]="profileMenu">
                <mat-icon aria-hidden="false" aria-label="profile icon" fontIcon="account_circle"></mat-icon>
            </button>
            <mat-menu #profileMenu>
                <mat-list role="list">
                    <mat-list-item role="listitem">
                        {{profile?.givenName}} {{profile?.surname}} ({{profile?.userPrincipalName}})
                    </mat-list-item>
                </mat-list>
                <mat-divider></mat-divider>
                <button mat-menu-item [routerLink]="['profile']">
                    <mat-icon aria-hidden="false" aria-label="profile icon" fontIcon="account_circle"></mat-icon>
                    View Profile
                </button>
                <button mat-menu-item [routerLink]="['/']">
                    <mat-icon aria-hidden="false" aria-label="home icon" fontIcon="home"></mat-icon>
                    Home
                </button>
                <mat-divider></mat-divider>
                <div mat-menu-item (click)="$event.stopPropagation()">
                    <mat-icon aria-hidden="false" aria-label="palette icon" fontIcon="palette"></mat-icon>
                    Theme <app-theme-toggle style="margin-left: 1rem;" (click)="changeTheme()"></app-theme-toggle>
                </div>
                <mat-divider></mat-divider>
                <button mat-menu-item (click)="logout()">
                    <mat-icon aria-hidden="false" aria-label="logout icon" fontIcon="logout"></mat-icon>
                    Log Out
                </button>
            </mat-menu>
        </div>
    </mat-toolbar>
    <mat-progress-bar *ngIf="loadingService.isLoading | async" mode="indeterminate"></mat-progress-bar>
</div>