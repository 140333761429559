import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Message } from './messages.model'; 

@Injectable({
  providedIn: 'root'
})
export class MessageService {
  private apiUrl = `${environment.applicationApi}/messages`; // Use the environment variable
  // we hard code the api url first to test the service
  constructor(private http: HttpClient) { }

  getMessages(): Observable<{ [index: string]: Message[] }> { 
    console.log(this.apiUrl);
    return this.http.get<{ [index: string]: Message[] }>(this.apiUrl); 
  }

  getFreightChatMessages(): Observable<{ [index: string]: Message[] }> {
    console.log(`${this.apiUrl}/freight-chat`);
    return this.http.get<{ [index: string]: Message[] }>(`${this.apiUrl}/freight-chat`);
  }

  getOriginationChatMessages(): Observable<{ [index: string]: Message[] }> {
    console.log(`${this.apiUrl}/origination-chat`);
    return this.http.get<{ [index: string]: Message[] }>(`${this.apiUrl}/origination-chat`);
  }
}   

