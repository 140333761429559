export interface CounterpartyMapping {
    code: string;
    fullName: string;
}

export const COUNTERPARTY_MAPPINGS: CounterpartyMapping[] = [
    { code: 'FREEPCTE', fullName: 'Freepoint Commodities Trading & Marketing LLC' },
    { code: 'DARE', fullName: 'DXT Commodities SA' },
    { code: 'TRAFI', fullName: 'Trafigura Pte Ltd' },
    { code: 'CHENIERE', fullName: 'Cheniere Energy, Inc.' },
    { code: 'VERCER', fullName: 'Mercuria Energy Trading S.A.' },
    { code: 'DAREGL', fullName: 'DXT Commodities SA' },
    { code: 'DVTRADING', fullName: 'DV Trading LLC' },
    { code: 'PCHK', fullName: 'PetroChina International Co. Ltd' },
    { code: 'GLENCORESG', fullName: 'Glencore Singapore Pte Ltd' },
    { code: 'MARUBENICO', fullName: 'Marubeni Corporation' },
    { code: 'UNIPECSG', fullName: 'Unipec Singapore Pte Ltd' },
    { code: 'GUNVORSG', fullName: 'Gunvor Singapore Pte Ltd' },
    { code: 'MARUBENI', fullName: 'Marubeni Corporation' },
    { code: 'SHELNG', fullName: 'Shell International Trading Middle East Ltd' },
    { code: 'BPSG', fullName: 'BP Singapore Pte Ltd' },
    { code: 'ITOCHU', fullName: 'Itochu Corporation' },
    { code: 'ENGIESG', fullName: 'ENGIE Singapore Pte Ltd' },
    { code: 'GUNVORBV', fullName: 'Gunvor Group Ltd' },
    { code: 'ONYX', fullName: 'Onyx Commodities Ltd' },
    { code: 'CMTENERGY', fullName: 'CMT Energy Pte Ltd' },
    { code: 'JERAGM', fullName: 'JERA Global Markets Pte Ltd' },
    { code: 'MACQEUGAS', fullName: 'Macquarie Energy LLC' },
    { code: 'GLENCOREUK', fullName: 'Glencore Energy UK Ltd' },
    { code: 'CHEVINCSG', fullName: 'Chevron International Pte Ltd' },
    { code: 'ENITASHIP', fullName: 'ENI Trading & Shipping S.p.A.' },
    { code: 'MERCURIASA', fullName: 'Mercuria Energy Trading S.A.' },
    { code: 'SEFE', fullName: 'SEFE Marketing & Trading Ltd' },
    { code: 'RWEST', fullName: 'RWE Supply & Trading GmbH' },
    { code: 'CCME', fullName: 'Castleton Commodities Merchant Trading L.P.' },
    { code: 'ADNOCTL', fullName: 'ADNOC Trading Ltd' },
    { code: 'TGPA', fullName: 'TotalEnergies Gas & Power Asia Pte Ltd' },
    { code: 'PETROCHINA', fullName: 'PetroChina International Co. Ltd' },
    { code: 'TGP', fullName: 'TotalEnergies Gas & Power Ltd' },
    { code: 'LITASCO', fullName: 'LITASCO SA' },
    { code: 'DGI', fullName: 'Diamond Gas International Pte Ltd' },
    { code: 'CHEV', fullName: 'Chevron Corporation' },
    { code: 'PTT', fullName: 'PTT Public Company Limited' },
    { code: 'VITOLSG', fullName: 'Vitol Asia Pte Ltd' },
    { code: 'PETRONALNG', fullName: 'PETRONAS LNG Ltd' },
    { code: 'UNIPER', fullName: 'Uniper Global Commodities SE' },
    { code: 'ENIGLOBAL', fullName: 'ENI Global Energy Markets S.p.A.' },
    { code: 'ARAMCOSG', fullName: 'Aramco Trading Singapore Pte Ltd' },
    { code: 'SIXONEASIA', fullName: 'Six One Commodities Pte Ltd' },
    { code: 'CNOOCSG', fullName: 'CNOOC Trading Singapore Pte Ltd' },
    { code: 'SEFESG', fullName: 'SEFE Energy Singapore Pte Ltd' },
    { code: 'MAQ', fullName: 'Macquarie Group Limited' },
    { code: 'HEP', fullName: 'Hartree Partners LP' },
    { code: 'ENBW', fullName: 'EnBW Energie Baden-Württemberg AG' },
    { code: 'ENNLNG', fullName: 'ENN LNG (Singapore) Pte Ltd' },
    { code: 'SCRTUKLtd', fullName: 'Socar Trading UK Ltd' },
    { code: 'WOODSIDEENSG', fullName: 'Woodside Energy Trading Singapore Pte Ltd' }
];

export const getCounterpartyFullName = (code: string): string => {
    const mapping = COUNTERPARTY_MAPPINGS.find(m => m.code === code);
    return mapping ? mapping.fullName : code;
}; 