<div>
    <h1>{{ title }}</h1>
    
    <div *ngIf="isLoadingTrades || isLoadingCounterpartyTrades || isLoadingFreightChat || isLoadingOriginationChat" 
         class="loading-message">
        <mat-icon class="loading-icon" [class.rotating]="isRetrying">sync</mat-icon>
        <span>
            {{ getLoadingMessage() }}
            <span *ngIf="searchError" class="error-message">{{ searchError }}</span>
        </span>
    </div>

    <div class="search-form">
        <div class="search-row">
            <div class="search-inputs">
                <mat-form-field appearance="fill" class="period-field">
                    <mat-label>Period</mat-label>
                    <input type="text" matInput [(ngModel)]="selectedPeriod" name="period" [matAutocomplete]="autoPeriod">
                    <mat-autocomplete #autoPeriod="matAutocomplete" (optionSelected)="onPeriodSelection($event.option.value)">
                        <mat-option *ngFor="let period of periods" [value]="period">{{ period }}</mat-option>
                    </mat-autocomplete>
                </mat-form-field>

                <mat-form-field appearance="fill" class="year-field">
                    <mat-label>Year</mat-label>
                    <input type="text" matInput [(ngModel)]="selectedYear" name="year" [matAutocomplete]="autoYear">
                    <mat-autocomplete #autoYear="matAutocomplete" (optionSelected)="onYearSelection($event.option.value)">
                        <mat-option *ngFor="let year of years" [value]="year">{{ year }}</mat-option>
                    </mat-autocomplete>
                </mat-form-field>

                <mat-form-field appearance="fill" class="search-field">
                    <mat-label>Search Terms</mat-label>
                    <input matInput [(ngModel)]="searchTerm" name="searchTerm" 
                           (keyup.enter)="onSearch()" />
                </mat-form-field>

                <button mat-raised-button color="primary" 
                        (click)="onSearch()"
                        [disabled]="isLoadingTrades || isLoadingCounterpartyTrades">
                    {{ (isLoadingTrades || isLoadingCounterpartyTrades) ? 'Loading...' : 'Search' }}
                </button>
            </div>
        </div>
        
        <div *ngIf="searchError" style="color: red; margin: 10px 0;">
            {{ searchError }}
        </div>
    </div>

    <div class="controls-row">
        <div class="filter-buttons">
            <mat-button-toggle-group multiple>
                <mat-button-toggle 
                    [checked]="messageFilters.showMessages"
                    [disabled]="messageFilters.showMessages && getActiveFilterCount() === 1"
                    (change)="onFilterChange('showMessages', $event.source.checked)">
                    <mat-icon>chat</mat-icon>
                    Trader Messages
                </mat-button-toggle>
                <mat-button-toggle 
                    [checked]="messageFilters.showFreightChat"
                    [disabled]="messageFilters.showFreightChat && getActiveFilterCount() === 1"
                    (change)="onFilterChange('showFreightChat', $event.source.checked)">
                    <mat-icon>chat_bubble</mat-icon>
                    Freight Market
                </mat-button-toggle>
                    <mat-button-toggle 
                    [checked]="messageFilters.showOriginationChat"
                    [disabled]="messageFilters.showOriginationChat && getActiveFilterCount() === 1"
                    (change)="onFilterChange('showOriginationChat', $event.source.checked)">
                    <mat-icon>forum</mat-icon>
                    Origination Market
                </mat-button-toggle>
                <mat-button-toggle 
                    [checked]="messageFilters.showTrades"
                    [disabled]="messageFilters.showTrades && getActiveFilterCount() === 1"
                    (change)="onFilterChange('showTrades', $event.source.checked)">
                    <mat-icon>directions_boat</mat-icon>
                    JeraGM Trades
                </mat-button-toggle>
                <mat-button-toggle 
                    [checked]="messageFilters.showPlattsFinancial"
                    [disabled]="messageFilters.showPlattsFinancial && getActiveFilterCount() === 1"
                    (change)="onFilterChange('showPlattsFinancial', $event.source.checked)">
                    <mat-icon>trending_up</mat-icon>
                    Platts Financial
                </mat-button-toggle>
                <mat-button-toggle 
                    [checked]="messageFilters.showPlattsPhysical"
                    [disabled]="messageFilters.showPlattsPhysical && getActiveFilterCount() === 1"
                    (change)="onFilterChange('showPlattsPhysical', $event.source.checked)">
                    <mat-icon>local_shipping</mat-icon>
                    Platts Physical
                </mat-button-toggle>
            </mat-button-toggle-group>
        </div>

        <div class="view-controls">
            <mat-button-toggle-group [(ngModel)]="viewMode" aria-label="View Mode">
                <mat-button-toggle value="table">
                    <mat-icon>table_chart</mat-icon>
                    Table
                </mat-button-toggle>
                <mat-button-toggle value="timeline">
                    <mat-icon>view_timeline</mat-icon>
                    Timeline
                </mat-button-toggle>
            </mat-button-toggle-group>

            <button mat-raised-button color="primary" 
                    [disabled]="!filteredMessages.length"
                    (click)="openGraphicalView(filteredMessages)">
                <mat-icon>timeline</mat-icon>
                Open Graphical View
            </button>
        </div>
    </div>

    <div *ngIf="filteredMessages.length > 0" style="margin-top: 20px;">
        <div *ngFor="let month of filteredMessages" class="month-container">
            <div class="month-header-container">
                <h2 class="month-header">
                    {{ searchOnlyMode ? 'Search Results' : 
                       (activeSearchYear ? 
                           (!selectedPeriod ? activeSearchYear : month.name + ' ' + month.year) : 
                           (!selectedPeriod && !selectedYear && !searchTerm.trim() ? 'Search Results for Last 3 Months' : 'Search Results')) }}
                </h2>
                <button mat-raised-button 
                        color="primary" 
                        (click)="exportToExcel(month)"
                        class="export-button">
                    <mat-icon>download</mat-icon>
                    Export to Excel
                </button>
            </div>
            
            <!-- Timeline View with Pagination -->
            <div *ngIf="viewMode === 'timeline'" class="timeline-container">
                <div *ngFor="let message of getPaginatedMessages(month.messages)" 
                     class="timeline-entry"
                     [ngClass]="{
                         'message-entry': !message.text?.startsWith('Trade:') && 
                                         !message.text?.startsWith('Platts Financial Trade') && 
                                         !message.text?.startsWith('Platts Physical Trade') &&
                                         !message.text?.startsWith('Freight') &&
                                         !message.text?.startsWith('Origination'),
                         'trade-entry': message.text?.startsWith('Trade:'),
                         'platts-financial-entry': message.text?.startsWith('Platts Financial Trade'),
                         'platts-physical-entry': message.text?.startsWith('Platts Physical Trade'),
                         'freight-chat-entry': message.text?.startsWith('Freight'),
                         'origination-chat-entry': message.text?.startsWith('Origination')
                     }">
                    <div class="entry-timestamp">{{ message.timestamp }}</div>
                    <div class="entry-content">
                        <mat-icon class="entry-icon" [ngStyle]="{
                            'color': message.text.startsWith('Asia LNG Derivative') ? '#fbc02d' : 
                                    message.text.startsWith('Asia LNG Physical') ? '#2e7d32' : 'gray'
                        }">
                            {{message.text.startsWith('Trade:') ? 'directions_boat' :
                               message.text.startsWith('Platts Financial Trade') ? 'trending_up' :
                               message.text.startsWith('Platts Physical Trade') ? 'local_shipping' :
                               message.text.startsWith('Freight') ? 'chat_bubble' :
                               message.text.startsWith('Origination') ? 'forum' : 'chat'}}
                        </mat-icon>
                        <div>
                            <div [innerHTML]="boldHeader(message.header)" 
                                 [ngClass]="{
                                   'asia-lng-derivative': message.text?.includes('Asia LNG Derivative'),
                                   'asia-lng-physical': message.text?.includes('Asia LNG Physical')
                                 }"></div>
                            <div class="message-text" [innerHTML]="message.text"></div>
                        </div>
                    </div>
                </div>
                
                <mat-paginator
                    [length]="month.messages.length"
                    [pageSize]="pageSize"
                    [pageSizeOptions]="pageSizeOptions"
                    [pageIndex]="currentPage"
                    (page)="onPageChange($event, month)"
                    aria-label="Select page">
                </mat-paginator>
            </div>

            <!-- Table View with Pagination -->
            <div *ngIf="viewMode === 'table'">
                <table mat-table [dataSource]="getPaginatedMessages(month.messages)" class="message-table">
                    <ng-container matColumnDef="header">
                        <th mat-header-cell *matHeaderCellDef class="header-column"> Entry </th>
                        <td mat-cell *matCellDef="let message">
                            <mat-icon class="entry-icon" [ngStyle]="{
                                'color': message.text.startsWith('Asia LNG Derivative') ? '#fbc02d' : 
                                        message.text.startsWith('Asia LNG Physical') ? '#2e7d32' : 'gray'
                            }">
                                {{message.text.startsWith('Trade:') ? 'directions_boat' :
                                   message.text.startsWith('Platts Financial Trade') ? 'trending_up' :
                                   message.text.startsWith('Platts Physical Trade') ? 'local_shipping' :
                                   message.text.startsWith('Freight') ? 'chat_bubble' :
                                   message.text.startsWith('Origination') ? 'forum' : 'chat'}}
                            </mat-icon>
                            <span [innerHTML]="boldHeader(message.header)" style="margin-left: 8px;"></span>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="text">
                        <th mat-header-cell *matHeaderCellDef class="text-column"> Details </th>
                        <td mat-cell *matCellDef="let message" class="message-cell" 
                            [ngStyle]="{
                                'color': message.text.startsWith('Asia LNG Derivative') ? '#fbc02d' : 
                                         message.text.startsWith('Asia LNG Physical') ? '#2e7d32' : 'inherit'
                            }"> 
                            <div class="message-text" [innerHTML]="message.text" style="white-space: pre-wrap; word-wrap: break-word;">
                            </div>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="timestamp">
                        <th mat-header-cell *matHeaderCellDef class="timestamp-column"> Time of Entry </th>
                        <td mat-cell *matCellDef="let message"> {{ message.timestamp }} </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="['header', 'text', 'timestamp']"></tr>
                    <tr mat-row *matRowDef="let row; columns: ['header', 'text', 'timestamp'];"
                        [ngClass]="{
                            'message-row': !row.text.startsWith('Trade:') && 
                                          !row.text.startsWith('Platts Financial Trade') && 
                                          !row.text.startsWith('Platts Physical Trade') &&
                                          !row.text.startsWith('Freight') &&
                                          !row.text.startsWith('Origination'),
                            'trade-row': row.text.startsWith('Trade:'),
                            'platts-financial-row': row.text.startsWith('Platts Financial Trade'),
                            'platts-physical-row': row.text.startsWith('Platts Physical Trade'),
                            'freight-chat-row': row.text.startsWith('Freight'),
                            'origination-chat-row': row.text.startsWith('Origination')
                        }">
                    </tr>
                </table>
                
                <mat-paginator
                    [length]="month.messages.length"
                    [pageSize]="pageSize"
                    [pageSizeOptions]="pageSizeOptions"
                    [pageIndex]="currentPage"
                    (page)="onPageChange($event, month)"
                    aria-label="Select page">
                </mat-paginator>
            </div>
        </div>
    </div>

    <div *ngIf="filteredMessages.length === 0" class="no-results-message" style="margin-top: 20px; text-align: center;">
        <mat-icon style="vertical-align: middle; margin-right: 8px;">info</mat-icon>
        No results found. Try adjusting your search criteria or filters.
    </div>
</div>