import { Component, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { CommonModule } from "@angular/common";
import { MessageService } from './message.service';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { DatePipe } from '@angular/common';
import { Trade } from './trade.model';
import { TradeService } from './trade.service';
import { CounterpartyTradeService } from './counterparty-trade.service';
import { CounterpartyTrade } from './counterparty-trade.model';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { GraphicalTimelineDialogComponent } from '../graphical-timeline-dialog/graphical-timeline-dialog.component';
import { MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { getCounterpartyFullName } from './counterparty-mappings';
import * as XLSX from 'xlsx';

interface Message {
    message: string;
    messageDates: string[];
    user: string;
    timestamp: string; 
}

interface FilteredMessage {
    name: string;
    year: string;
    messages: { text: string; header: string; timestamp: string }[];
}

@Component({
  selector: 'app-market-blotter',
  templateUrl: './marketblotter.component.html',
  styleUrls: ['./marketblotter.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatSelectModule,
    MatButtonModule,
    MatFormFieldModule,
    MatCardModule,
    MatIconModule,
    MatTableModule,
    CommonModule,
    MatAutocompleteModule,
    MatButtonToggleModule,
    MatDialogModule,
    MatPaginatorModule
    ],
  providers: [DatePipe]
})
export class MarketBlotterComponent implements OnInit {
  title = 'Market Blotter';
  periods = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', 'Q1', 'Q2', 'Q3', 'Q4', 'Summer', 'Winter'];
  years: number[] = this.generateYears(); 
  selectedPeriod = '';
  selectedYear: number | null = null;
  searchTerm = '';
  messages: { [index: string]: Message[] } = {}
  filteredMessages: FilteredMessage[] = [];
  searchError: string = '';
  searchOnlyMode = false;
  trades: Trade[] = [];
  isLoadingTrades = false;
  counterpartyTrades: CounterpartyTrade[] = [];
  isLoadingCounterpartyTrades = false;
  private tradesLoaded = false;
  private counterpartyTradesLoaded = false;
  messageFilters = {
    showMessages: true,
    showTrades: true,
    showPlattsFinancial: true,
    showPlattsPhysical: true,
    showFreightChat: true
  };
  viewMode: 'table' | 'timeline' | 'graphical' = 'table';
  hasSearchResults = false;

  // Add pagination properties
  pageSize = 100;
  pageSizeOptions = [50, 100, 200, 400];
  currentPage = 0;
  
  // Method to handle page changes
  onPageChange(event: PageEvent, monthMessages: FilteredMessage) {
    this.currentPage = event.pageIndex;
    this.pageSize = event.pageSize;
  }

  // Method to get paginated messages for a specific month
  getPaginatedMessages(messages: any[]): any[] {
    const startIndex = this.currentPage * this.pageSize;
    return messages.slice(startIndex, startIndex + this.pageSize);
  }

  // Add this new method
  private generateYears(): number[] {
      const startYear = 2024;
      const currentYear = new Date().getFullYear();
      const numberOfYearsAhead = 2; 
      const endYear = currentYear + numberOfYearsAhead;
      
      const years: number[] = [];
      for (let year = startYear; year <= endYear; year++) {
        years.push(year);
      }
      return years;
    }

  // Add a new property to store the active search year
  activeSearchYear: number | null = null;

  // Add new property
  isLoadingFreightChat = false;
  freightChatMessages: { [key: string]: Message[] } = {};

  // Add these properties to the component
  retryCount = 3; // Match the default retry count from loadTradesWithRetry
  currentRetryAttempt = 0;
  isRetrying = false;

  constructor(
    private messageService: MessageService,
    private tradeService: TradeService,
    private counterpartyTradeService: CounterpartyTradeService,
    private datePipe: DatePipe,
    private dialog: MatDialog
  ) { }

  openGraphicalView(filteredMessages: any[]) {
    // Flatten all messages from all months into a single array
    const allMessages = filteredMessages.reduce((acc: any[], month: any) => {
      return acc.concat(month.messages);
    }, []);

    const periodTitle = this.selectedPeriod && this.selectedYear ? 
      `${this.selectedPeriod} ${this.selectedYear}` : 
      this.searchTerm ? `Search Results: "${this.searchTerm}"` : 
      'All Messages';

    const dialogRef = this.dialog.open(GraphicalTimelineDialogComponent, {
      width: '90vw',
      height: '90vh',
      data: { 
        messages: allMessages,
        title: periodTitle
      }
    });
  }

  loadMessages() {
    // Load regular messages
    this.messageService.getMessages().subscribe(data => {
      console.log('Market chat data:', data);
      this.messages = this.processData(data);
      this.decodeMessages();
      console.log('Processed Messages:', this.messages);
    }, error => {
      console.error('Error fetching messages:', error);
    });

    // Load FreightChat messages
    this.isLoadingFreightChat = true;
    this.messageService.getFreightChatMessages().subscribe({
      next: (data) => {
        console.log('FreightChat data:', data);
        this.freightChatMessages = this.processData(data);
        this.decodeFreightChatMessages();
        console.log('Processed FreightChat Messages:', this.freightChatMessages);
        this.isLoadingFreightChat = false;
      },
      error: (error) => {
        console.error('Error fetching FreightChat messages:', error);
        this.isLoadingFreightChat = false;
      }
    });
  }

  processData(data: any): { [index: string]: Message[] } {
    console.log('Raw data:', data);
    const processedMessages: { [index: string]: Message[] } = {};
    data.forEach((item: any) => {
        const key = item.subject;
        if (!processedMessages[key]) {
            processedMessages[key] = [];
        }

        // Handle null or missing messageDates
        let messageDates: string[] = [];
        if (item.messageDates) {
            messageDates = item.messageDates.split(',')
                .map((date: string) => date.trim())
                .filter((date: string) => date)
                .map((date: string) => {
                    const month = date.slice(0, 3);
                    const year = date.slice(3);
                    return `${month} ${year}`;
                });
        }

        processedMessages[key].push({
            message: item.message,
            messageDates: messageDates, // Will be empty array if messageDates was null/undefined
            user: item.user,
            timestamp: item.rowKey
        });
    });
    console.log('Processed messages:', processedMessages);
    return processedMessages;
  }

  decodeMessages() {
    const removeHashtag = (key: string) => key.replace(/^#/, '');
    const updatedMessages: { [index: string]: Message[] } = {};

    for (const key in this.messages) {
      if (this.messages.hasOwnProperty(key)) {
        const newKey = removeHashtag(key);
        updatedMessages[newKey] = this.messages[key].map(message => {
          return {
            ...message,
            message: decodeURIComponent(message.message).replace(/\n/g, '')
          };
        });
      }
    }

    this.messages = updatedMessages;
  }

  // Add helper method for FreightChat messages
  private decodeFreightChatMessages() {
    for (const key in this.freightChatMessages) {
      if (this.freightChatMessages.hasOwnProperty(key)) {
        this.freightChatMessages[key] = this.freightChatMessages[key].map(message => ({
          ...message,
          message: decodeURIComponent(message.message)
        }));
      }
    }
  }

  onPeriodSelection(selectedPeriod: string) {
    this.selectedPeriod = selectedPeriod;
    console.log(this.selectedPeriod)
  }

  onYearSelection(selectedYear: number) {
    this.selectedYear = selectedYear;
    console.log(this.selectedYear)
  }

  ngOnInit() {
    this.initializeCurrentPeriodAndYear();
    this.loadMessages();
    
    console.log('Starting trade loading process...');
    this.loadTradesWithRetry();
  }

  private async loadTradesWithRetry(retryCount = 3, delayMs = 2000): Promise<void> {
    this.retryCount = retryCount;
    this.currentRetryAttempt = this.retryCount - retryCount + 1;
    this.isRetrying = retryCount < 3;
    
    console.log(`Attempting to load trades (${retryCount} retries remaining)`);
    
    const retryLoadTrades = async () => {
        try {
            await this.loadTrades();
            if (this.trades.length === 0) {
                throw new Error('No main trades loaded');
            }
            return true;
        } catch (error) {
            console.error('Main trades failed:', error);
            return false;
        }
    };

    const retryLoadCounterpartyTrades = async () => {
        try {
            await this.loadCounterpartyTrades();
            if (this.counterpartyTrades.length === 0) {
                throw new Error('No counterparty trades loaded');
            }
            return true;
        } catch (error) {
            console.error('Counterparty trades failed:', error);
            return false;
        }
    };

    let mainTradesSuccess = await retryLoadTrades();
    let counterpartyTradesSuccess = await retryLoadCounterpartyTrades();
    let currentRetry = retryCount;

    while (((!mainTradesSuccess || !counterpartyTradesSuccess)) && currentRetry > 0) {
        console.log(`Retrying failed loads. Attempts remaining: ${currentRetry}`);
        this.isRetrying = true;
        
        // Wait before retrying
        await new Promise(resolve => setTimeout(resolve, delayMs));
        
        // Only retry the failed loads
        if (!mainTradesSuccess) {
            mainTradesSuccess = await retryLoadTrades();
        }
        if (!counterpartyTradesSuccess) {
            counterpartyTradesSuccess = await retryLoadCounterpartyTrades();
        }
        
        currentRetry--;
    }

    // Final status check
    if (!mainTradesSuccess || !counterpartyTradesSuccess) {
        console.error('Failed to load all trade data after retries');
        this.searchError = 'Unable to load complete trade data. Please refresh the page.';
        this.isRetrying = false;
        return;
    }

    // Success case - we have all data
    console.log('Successfully loaded all trades:', {
        trades: this.trades.length,
        counterpartyTrades: this.counterpartyTrades.length
    });
    this.searchError = '';
    this.isRetrying = false;
    this.onSearch();
  }

  isValidSearch(): boolean {
    // Allow empty searches - will default to last 3 months
    if (!this.selectedPeriod && !this.selectedYear && !this.searchTerm.trim()) {
        this.searchError = '';
        return true;
    }
    
    // Check other valid combinations
    if (this.selectedPeriod && this.selectedYear) {
        this.searchError = '';
        return true;
    } else if (this.selectedYear && !this.selectedPeriod) {
        this.searchError = '';
        return true;
    } else if (!this.selectedPeriod && !this.selectedYear && this.searchTerm.trim()) {
        this.searchError = '';
        return true;
    }
    
    this.searchError = 'Please enter either: Year only, Year and Period, Year and Search Term or Year and Search Term and Period';
    return false;
  }

  private sortMessagesByTimestamp(messages: { text: string; header: string; timestamp: string }[]) {
    return messages.sort((a, b) => {
        const timeA = new Date(a.timestamp).getTime();
        const timeB = new Date(b.timestamp).getTime();
        return timeB - timeA; // Descending order
    });
  }

  // Add this helper method to check if a date string is within the last 3 months
  private isWithinLastThreeMonths(dateStr: string): boolean {
    try {
        const date = new Date(dateStr);
        const threeMonthsAgo = new Date();
        threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3);
        return date >= threeMonthsAgo && date <= new Date();
    } catch (error) {
        console.error('Error parsing date:', dateStr, error);
        return false;
    }
  }

  onSearch() {
    this.currentPage = 0;
    this.activeSearchYear = this.selectedYear;
    
    if (!this.isValidSearch()) {
        return;
    }

    if (this.isLoadingTrades || this.isLoadingCounterpartyTrades) {
        this.searchError = 'Please wait for trades to finish loading';
        return;
    }
    
    if (this.trades.length === 0 && this.counterpartyTrades.length === 0) {
        this.searchError = 'No trade data available';
        return;
    }

    this.searchError = '';
    this.searchOnlyMode = !this.selectedPeriod && !this.selectedYear && !!this.searchTerm.trim();
    const yearOnlyMode = !this.selectedPeriod && !!this.selectedYear;
    const yearWithSearchMode = !this.selectedPeriod && !!this.selectedYear && !!this.searchTerm.trim();
    const defaultMode = !this.selectedPeriod && !this.selectedYear && !this.searchTerm.trim();
    
    this.filteredMessages = [];
    const searchTermLower = this.searchTerm.toLowerCase();
    const uniqueMessages = new Set<string>();

    if (defaultMode) {
        // Last 3 months mode
        const defaultMessages: FilteredMessage = {
            name: 'Last 3 Months',
            year: '',
            messages: []
        };

        // Get trades using the search methods
        const tradeResults = this.searchTrades('', '', undefined);
        const counterpartyTradeResults = this.searchCounterpartyTrades('', '', undefined);
        defaultMessages.messages.push(...tradeResults, ...counterpartyTradeResults);

        // Filter regular messages for last 3 months
        for (const key in this.messages) {
            if (this.messages.hasOwnProperty(key)) {
                const messages = this.messages[key];
                messages.forEach(message => {
                    if (this.isWithinLastThreeMonths(message.timestamp)) {
                        const formattedTimestamp = this.datePipe.transform(message.timestamp, 'medium') || '';
                        const uniqueKey = `${message.message}-${message.user}-${formattedTimestamp}`;
                        
                        if (!uniqueMessages.has(uniqueKey)) {
                            uniqueMessages.add(uniqueKey);
                            defaultMessages.messages.push({
                                text: message.message,
                                header: `${key} | ${message.user}`,
                                timestamp: formattedTimestamp
                            });
                        }
                    }
                });
            }
        }

        // Add FreightChat messages for last 3 months
        for (const key in this.freightChatMessages) {
            if (this.freightChatMessages.hasOwnProperty(key)) {
                const messages = this.freightChatMessages[key];
                messages.forEach(message => {
                    if (this.isWithinLastThreeMonths(message.timestamp)) {
                        const formattedTimestamp = this.datePipe.transform(message.timestamp, 'medium') || '';
                        const uniqueKey = `${message.message}-${message.user}-${formattedTimestamp}`;
                        
                        if (!uniqueMessages.has(uniqueKey)) {
                            uniqueMessages.add(uniqueKey);
                            defaultMessages.messages.push({
                                text: `Freight Market: ${message.message}`,
                                header: `${key} | ${message.user}`,
                                timestamp: formattedTimestamp
                            });
                        }
                    }
                });
            }
        }

        // Sort all messages by timestamp
        defaultMessages.messages = this.sortMessagesByTimestamp(defaultMessages.messages);
        
        // Only add to filtered messages if we have results
        if (defaultMessages.messages.length > 0) {
            this.filteredMessages = [defaultMessages];
        }
    } else if (yearWithSearchMode || yearOnlyMode) {
        // Year search mode (with optional search terms)
        const yearMessages: FilteredMessage = {
            name: '',  // Empty name since we'll show just the year in the header
            year: this.selectedYear?.toString() || '',
            messages: []
        };

        // Get trades using the search methods with optional search term
        const tradeResults = this.searchTrades(yearWithSearchMode ? this.searchTerm : '', '', this.selectedYear || undefined);
        const counterpartyTradeResults = this.searchCounterpartyTrades(yearWithSearchMode ? this.searchTerm : '', '', this.selectedYear || undefined);
        yearMessages.messages.push(...tradeResults, ...counterpartyTradeResults);

        // Filter messages by year and optional search term
        for (const key in this.messages) {
            if (this.messages.hasOwnProperty(key)) {
                const messages = this.messages[key];
                messages.forEach(message => {
                    message.messageDates.forEach(date => {
                        const [, year] = date.split(' ');
                        if (parseInt(year) === this.selectedYear) {
                            // If we have a search term, check if the message matches
                            if (!yearWithSearchMode || 
                                key.toLowerCase().includes(searchTermLower) ||
                                message.message.toLowerCase().includes(searchTermLower) ||
                                message.user.toLowerCase().includes(searchTermLower)) {
                                
                                const formattedTimestamp = this.datePipe.transform(message.timestamp, 'medium') || '';
                                const uniqueKey = `${message.message}-${message.user}-${formattedTimestamp}`;
                                
                                if (!uniqueMessages.has(uniqueKey)) {
                                    uniqueMessages.add(uniqueKey);
                                    yearMessages.messages.push({
                                        text: message.message,
                                        header: `${key} | ${message.user}`,
                                        timestamp: formattedTimestamp
                                    });
                                }
                            }
                        }
                    });
                });
            }
        }

        // Sort all messages by timestamp
        yearMessages.messages = this.sortMessagesByTimestamp(yearMessages.messages);
        
        // Only add to filtered messages if we have results
        if (yearMessages.messages.length > 0) {
            this.filteredMessages = [yearMessages];
        }
    } else if (this.searchOnlyMode) {
        // Existing search-only mode logic
        const searchResults: FilteredMessage = {
            name: 'Search Results',
            year: '',
            messages: []
        };

        const tradeResults = this.searchTrades(this.searchTerm);
        const counterpartyTradeResults = this.searchCounterpartyTrades(this.searchTerm);
        searchResults.messages.push(...tradeResults, ...counterpartyTradeResults);

        for (const key in this.messages) {
            if (this.messages.hasOwnProperty(key)) {
                const messages = this.messages[key];
                messages.forEach(message => {
                    if (key.toLowerCase().includes(searchTermLower) ||
                        message.message.toLowerCase().includes(searchTermLower) ||
                        message.user.toLowerCase().includes(searchTermLower)) {
                        
                        const formattedTimestamp = this.datePipe.transform(message.timestamp, 'medium') || '';
                        const uniqueKey = `${message.message}-${message.user}-${formattedTimestamp}`;
                        
                        if (!uniqueMessages.has(uniqueKey)) {
                            uniqueMessages.add(uniqueKey);
                            searchResults.messages.push({
                                text: message.message,
                                header: `${key} | ${message.user}`,
                                timestamp: formattedTimestamp
                            });
                        }
                    }
                });
            }
        }

        if (searchResults.messages.length > 0) {
            searchResults.messages = this.sortMessagesByTimestamp(searchResults.messages);
            this.filteredMessages = [searchResults];
        }
    } else {
        // Period + Year mode
        const periodMonths = this.getPeriodMonths(this.selectedPeriod);
        const tradeResults = this.searchTrades(this.searchTerm, this.selectedPeriod, this.selectedYear || undefined);
        const counterpartyTradeResults = this.searchCounterpartyTrades(this.searchTerm, this.selectedPeriod, this.selectedYear || undefined);
        
        if (tradeResults.length > 0 || counterpartyTradeResults.length > 0) {
            let periodLabel = this.selectedPeriod;
            let periodMessages = this.filteredMessages.find(m => 
                m.name === periodLabel && m.year === this.selectedYear?.toString());
            
            if (!periodMessages) {
                periodMessages = {
                    name: periodLabel,
                    year: this.selectedYear?.toString() || '',
                    messages: [],
                };
                this.filteredMessages.push(periodMessages);
            }
            
            periodMessages.messages.push(...tradeResults, ...counterpartyTradeResults);
        }
        
        for (const key in this.messages) {
            if (this.messages.hasOwnProperty(key)) {
                const messages = this.messages[key];
                messages.forEach(message => {
                    if (!this.searchTerm || 
                        key.toLowerCase().includes(searchTermLower) ||
                        message.message.toLowerCase().includes(searchTermLower) ||
                        message.user.toLowerCase().includes(searchTermLower)) {

                        message.messageDates.forEach(date => {
                            const [month, year] = date.split(' ');
                            const isYearMatch = periodMonths.some(pm => 
                                parseInt(year) === this.selectedYear! + pm.yearOffset);
                            const isPeriodMatch = periodMonths.some(pm => pm.month === month);

                            if (isYearMatch && isPeriodMatch) {
                                let periodLabel = this.selectedPeriod;
                                let periodMessages = this.filteredMessages.find(m => 
                                    m.name === periodLabel && m.year === this.selectedYear?.toString());
                                
                                if (!periodMessages) {
                                    periodMessages = {
                                        name: periodLabel,
                                        year: this.selectedYear?.toString() || '',
                                        messages: [],
                                    };
                                    this.filteredMessages.push(periodMessages);
                                }

                                const formattedTimestamp = this.datePipe.transform(message.timestamp, 'medium') || '';
                                const uniqueKey = `${message.message}-${message.user}-${formattedTimestamp}`;
                                
                                if (!uniqueMessages.has(uniqueKey)) {
                                    uniqueMessages.add(uniqueKey);
                                    periodMessages.messages.push({
                                        text: message.message,
                                        header: `${key} | ${message.user}`,
                                        timestamp: formattedTimestamp
                                    });
                                }
                            }
                        });
                    }
                });
            }
        }
    }

    // Include FreightChat messages in search
    for (const key in this.freightChatMessages) {
      if (this.freightChatMessages.hasOwnProperty(key)) {
        const messages = this.freightChatMessages[key];
        messages.forEach(message => {
          let matchesPeriodAndYear = true;
          
          // If we have period/year criteria, check messageDates
          if (this.selectedYear || this.selectedPeriod) {
            matchesPeriodAndYear = false;
            const periodMonths = this.selectedPeriod ? this.getPeriodMonths(this.selectedPeriod) : null;
            
            message.messageDates.forEach(date => {
              const [month, year] = date.split(' ');
              if (this.selectedYear) {
                if (periodMonths) {
                  // Check both month and year
                  matchesPeriodAndYear = matchesPeriodAndYear || periodMonths.some(pm => {
                    const matchesMonth = pm.month === month;
                    const matchesYear = parseInt(year) === (this.selectedYear! + pm.yearOffset);
                    return matchesMonth && matchesYear;
                  });
                } else {
                  // Check only year
                  matchesPeriodAndYear = matchesPeriodAndYear || parseInt(year) === this.selectedYear;
                }
              }
            });
          }

          if ((key.toLowerCase().includes(searchTermLower) ||
              message.message.toLowerCase().includes(searchTermLower) ||
              message.user.toLowerCase().includes(searchTermLower)) &&
              matchesPeriodAndYear) {
              
            const formattedTimestamp = this.datePipe.transform(message.timestamp, 'medium') || '';
            const uniqueKey = `${message.message}-${message.user}-${formattedTimestamp}`;
            
            if (!uniqueMessages.has(uniqueKey)) {
              uniqueMessages.add(uniqueKey);
              // Find the appropriate filtered message group to add to
              let targetGroup = this.filteredMessages.find(fm => 
                fm.name === (this.searchOnlyMode ? 'Search Results' : this.selectedPeriod));
              
              if (!targetGroup) {
                targetGroup = {
                  name: this.searchOnlyMode ? 'Search Results' : this.selectedPeriod,
                  year: this.selectedYear?.toString() || '',
                  messages: []
                };
                this.filteredMessages.push(targetGroup);
              }
              
              targetGroup.messages.push({
                text: `Freight Market: ${message.message}`,
                header: `${key} | ${message.user}`,
                timestamp: formattedTimestamp
              });
            }
          }
        });
      }
    }

    // Apply filters and sort
    this.filteredMessages.forEach(fm => {
        fm.messages = this.filterMessages(fm.messages);
        fm.messages = this.sortMessagesByTimestamp(fm.messages);
    });

    // Remove any empty groups after filtering
    this.filteredMessages = this.filteredMessages.filter(fm => fm.messages.length > 0);

    // Update search results status
    this.hasSearchResults = this.filteredMessages.length > 0;
    
    // Reset view mode if no results
    if (!this.hasSearchResults) {
        this.viewMode = 'table';
    }
  }

    getPeriodMonths(period: string): { month: string, yearOffset: number }[] {
        if (!period) {
            return [
                { month: 'Jan', yearOffset: 0 }, { month: 'Feb', yearOffset: 0 }, { month: 'Mar', yearOffset: 0 },
                { month: 'Apr', yearOffset: 0 }, { month: 'May', yearOffset: 0 }, { month: 'Jun', yearOffset: 0 },
                { month: 'Jul', yearOffset: 0 }, { month: 'Aug', yearOffset: 0 }, { month: 'Sep', yearOffset: 0 },
                { month: 'Oct', yearOffset: 0 }, { month: 'Nov', yearOffset: 0 }, { month: 'Dec', yearOffset: 0 }
            ];
        }

        switch (period) {
            case 'Q1':
                return [{ month: 'Jan', yearOffset: 0 }, { month: 'Feb', yearOffset: 0 }, { month: 'Mar', yearOffset: 0 }];
            case 'Q2':
                return [{ month: 'Apr', yearOffset: 0 }, { month: 'May', yearOffset: 0 }, { month: 'Jun', yearOffset: 0 }];
            case 'Q3':
                return [{ month: 'Jul', yearOffset: 0 }, { month: 'Aug', yearOffset: 0 }, { month: 'Sep', yearOffset: 0 }];
            case 'Q4':
                return [{ month: 'Oct', yearOffset: 0 }, { month: 'Nov', yearOffset: 0 }, { month: 'Dec', yearOffset: 0 }];
            case 'Summer':
                return [{ month: 'Jun', yearOffset: 0 }, { month: 'Jul', yearOffset: 0 }, { month: 'Aug', yearOffset: 0 }];
            case 'Winter':
                return [{ month: 'Dec', yearOffset: 0 }, { month: 'Jan', yearOffset: 1 }, { month: 'Feb', yearOffset: 1 }];
            default:
                return [{ month: period, yearOffset: 0 }];
        }
    }

  boldHeader(header: string): string {
    const [boldPart, rest] = header.split(' | ');
    const capitalizedBoldPart = boldPart.toUpperCase();
    return `<b>${capitalizedBoldPart}</b> | ${rest}`;
  }

  searchTrades(searchTerm: string, selectedPeriod?: string, selectedYear?: number): { text: string; header: string; timestamp: string }[] {
    console.log('Starting searchTrades with:', {
        searchTerm,
        selectedPeriod,
        selectedYear,
        totalTrades: this.trades.length
    });
    
    const searchResults: { text: string; header: string; timestamp: string }[] = [];
    const uniqueTrades = new Set<string>();
    const periodMonths = selectedPeriod ? this.getPeriodMonths(selectedPeriod) : null;
    
    // Get default date range if no parameters supplied
    const useDefaultRange = !selectedPeriod && !selectedYear && !searchTerm;
    const defaultRange = useDefaultRange ? this.getLastThreeMonthsRange() : null;
    
    this.trades.forEach(trade => {
        let matchesPeriodAndYear = true;
        const tradeDate = new Date(trade.tradeDate);
        
        if (useDefaultRange) {
            // Check if trade falls within the last 3 months
            matchesPeriodAndYear = tradeDate >= defaultRange!.startDate && 
                                 tradeDate <= defaultRange!.endDate;
        } else if (selectedYear) {
            try {
                const tradeYear = tradeDate.getFullYear();
                
                if (selectedPeriod && periodMonths) {
                    const tradeMonth = tradeDate.toLocaleString('en-US', { month: 'short' });
                    matchesPeriodAndYear = periodMonths.some(pm => {
                        const matchesMonth = tradeMonth === pm.month;
                        const matchesYear = tradeYear === (selectedYear + pm.yearOffset);
                        return matchesMonth && matchesYear;
                    });
                } else {
                    matchesPeriodAndYear = tradeYear === selectedYear;
                }
            } catch (error) {
                console.error('Error parsing trade date:', trade.tradeDate, error);
                matchesPeriodAndYear = false;
            }
        }

        if ((!searchTerm || trade.counterparty.toLowerCase().includes(searchTerm.toLowerCase())) && 
            matchesPeriodAndYear) {
            try {
                const dateObj = new Date(trade.tradeDate);
                
                if (!isNaN(dateObj.getTime())) {
                    const formattedTimestamp = this.datePipe.transform(dateObj, 'medium') || trade.tradeDate;
                    const uniqueKey = `TRADE-${trade.tradeId}-${formattedTimestamp}`;
                    
                    if (!uniqueTrades.has(uniqueKey)) {
                        uniqueTrades.add(uniqueKey);
                        
                        const reference = trade.customPositionReference || trade.customTradeReference;
                        const referenceText = reference ? ` | ${reference}` : '';
                        
                        searchResults.push({
                            text: `Trade: ${trade.tradeId}_${trade.position}${referenceText} | JeraGM ${trade.positionType} ${trade.incoterms} ${trade.location}`,
                            header: `${trade.counterparty} | JERAGM Trades`,
                            timestamp: formattedTimestamp
                        });
                    }
                }
            } catch (error) {
                console.error('Date parsing error for:', trade.tradeDate);
                searchResults.push({
                    text: `Trade: ${trade.tradeId} | Quantity: ${trade.totalQuantity} | Type: ${trade.positionType}`,
                    header: `${trade.counterparty}`,
                    timestamp: trade.tradeDate
                });
            }
        }
    });
    
    console.log('searchTrades results:', {
        totalResults: searchResults.length,
        firstFewResults: searchResults.slice(0, 3)
    });
    
    return searchResults;
  }

  loadTrades(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
        if (this.isLoadingTrades) {
            console.warn('Trade loading already in progress');
            reject('Trade loading already in progress');
            return;
        }

        this.isLoadingTrades = true;
        console.log('Starting trades load...');

        this.tradeService.getTrades().subscribe({
            next: (trades) => {
                console.log('Trades loaded successfully:', {
                    count: trades.length,
                    sample: trades.length > 0 ? trades[0] : null
                });
                this.trades = trades;
                this.isLoadingTrades = false;
                resolve();
            },
            error: (error) => {
                console.error('Error in loadTrades:', error);
                this.isLoadingTrades = false;
                reject(error);
            },
            complete: () => {
                console.log('Trade loading observable completed');
                this.isLoadingTrades = false;
            }
        });
    });
  }

  loadCounterpartyTrades(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
        if (this.isLoadingCounterpartyTrades) {
            console.warn('Counterparty trade loading already in progress');
            reject('Counterparty trade loading already in progress');
            return;
        }

        this.isLoadingCounterpartyTrades = true;
        console.log('Starting counterparty trades load...');

        this.counterpartyTradeService.getCounterpartyTrades().subscribe({
            next: (trades) => {
                console.log('Counterparty trades loaded successfully:', {
                    count: trades.length,
                    sample: trades.length > 0 ? trades[0] : null
                });
                this.counterpartyTrades = trades;
                this.isLoadingCounterpartyTrades = false;
                resolve();
            },
            error: (error) => {
                console.error('Error in loadCounterpartyTrades:', error);
                this.isLoadingCounterpartyTrades = false;
                reject(error);
            },
            complete: () => {
                console.log('Counterparty trade loading observable completed');
                this.isLoadingCounterpartyTrades = false;
            }
        });
    });
  }

  searchCounterpartyTrades(searchTerm: string, selectedPeriod?: string, selectedYear?: number): { text: string; header: string; timestamp: string }[] {
    const searchResults: { text: string; header: string; timestamp: string }[] = [];
    const uniqueTrades = new Set<string>();
    const periodMonths = selectedPeriod ? this.getPeriodMonths(selectedPeriod) : null;
    const searchTermLower = searchTerm.toLowerCase();
    
    // Get default date range if no parameters supplied
    const useDefaultRange = !selectedPeriod && !selectedYear && !searchTerm;
    const defaultRange = useDefaultRange ? this.getLastThreeMonthsRange() : null;
    
    this.counterpartyTrades.forEach(trade => {
        // Get full names for both buyer and seller
        const buyerFullName = getCounterpartyFullName(trade.buyerMnemonic);
        const sellerFullName = getCounterpartyFullName(trade.sellerMnemonic);
        
        // Check if search term matches either code or full name
        const matchesSearch = !searchTerm || 
            trade.buyerMnemonic.toLowerCase().includes(searchTermLower) ||
            trade.sellerMnemonic.toLowerCase().includes(searchTermLower) ||
            buyerFullName.toLowerCase().includes(searchTermLower) ||
            sellerFullName.toLowerCase().includes(searchTermLower);

        let matchesPeriodAndYear = true;
        const tradeDate = new Date(trade.orderDate);
        
        if (useDefaultRange) {
            // Check if trade falls within the last 3 months
            matchesPeriodAndYear = tradeDate >= defaultRange!.startDate && 
                                 tradeDate <= defaultRange!.endDate;
        } else if (selectedYear) {
            try {
                const tradeYear = tradeDate.getFullYear();
                
                if (selectedPeriod && periodMonths) {
                    const tradeMonth = tradeDate.toLocaleString('en-US', { month: 'short' });
                    matchesPeriodAndYear = periodMonths.some(pm => {
                        const matchesMonth = tradeMonth === pm.month;
                        const matchesYear = tradeYear === (selectedYear + pm.yearOffset);
                        return matchesMonth && matchesYear;
                    });
                } else {
                    matchesPeriodAndYear = tradeYear === selectedYear;
                }
            } catch (error) {
                console.error('Error parsing trade date:', trade.orderDate, error);
                matchesPeriodAndYear = false;
            }
        }

        if (matchesSearch && matchesPeriodAndYear) {
            try {
                const dateObj = new Date(trade.orderDate);
                const formattedTimestamp = !isNaN(dateObj.getTime()) 
                    ? (this.datePipe.transform(dateObj, 'medium') || trade.orderDate)
                    : trade.orderDate;
                    
                // Create a more unique key using additional trade properties
                const uniqueKey = `CTRADE-${trade.orderDate}-${trade.buyerMnemonic}-${trade.sellerMnemonic}-${trade.lotSize}-${trade.market}`;
                
                if (!uniqueTrades.has(uniqueKey)) {
                    uniqueTrades.add(uniqueKey);
                    
                    const isDerivative = trade.market.includes('Derivative');
                    const plattsType = isDerivative ? 'Platts Financial Trade' : 'Platts Physical Trade';
                    
                    searchResults.push({
                        text: `${plattsType} | ` +
                             `Buyer: ${trade.buyerMnemonic} | ` + 
                             `Seller: ${trade.sellerMnemonic} | ` +
                             `Quantity: ${trade.lotSize} ${trade.lotUnit} | Market: ${trade.market}`,
                            //  `Price: ${trade.price} ${trade.priceUnit}`, 
                        header: `${trade.buyerMnemonic} ↔ ${trade.sellerMnemonic} | ${plattsType}`,
                        timestamp: formattedTimestamp,
                    });
                }
            } catch (error) {
                console.error('Date parsing error for:', trade.orderDate, error);
                searchResults.push({
                    text: `Counterparty Trade: ${trade.orderDate} | Error processing trade details`,
                    header: `${trade.buyerMnemonic} ↔ ${trade.sellerMnemonic} | Platts Trades`,
                    timestamp: trade.orderDate
                });
            }
        }
    });
    
    return searchResults;
  }

  filterMessages(messages: any[]): any[] {
    return messages.filter(message => {
        // When only FreightChat filter is active, return any message containing Freight
        if (this.messageFilters.showFreightChat && 
            !this.messageFilters.showMessages && 
            !this.messageFilters.showTrades && 
            !this.messageFilters.showPlattsFinancial && 
            !this.messageFilters.showPlattsPhysical) {
            return message.header?.includes('Freight') || 
                   message.text?.includes('Freight');
        }

        // For all other cases, check message type and corresponding filter
        if (message.text?.startsWith('Trade:')) {
            return this.messageFilters.showTrades;
        }
        if (message.text?.startsWith('Platts Financial Trade')) {
            return this.messageFilters.showPlattsFinancial;
        }
        if (message.text?.startsWith('Platts Physical Trade')) {
            return this.messageFilters.showPlattsPhysical;
        }
        if (message.header?.includes('Freight') || message.text?.includes('Freight')) {
            return this.messageFilters.showFreightChat;
        }
        // Any other message is a regular message
        return this.messageFilters.showMessages;
    });
  }

  // Add this method to handle filter changes
  onFilterChange(filterType: 'showMessages' | 'showTrades' | 'showPlattsFinancial' | 'showPlattsPhysical' | 'showFreightChat', checked: boolean) {
    this.messageFilters[filterType] = checked;
    this.onSearch();
  }

  private initializeCurrentPeriodAndYear() {
    const now = new Date();
    this.selectedYear = now.getFullYear();
    this.selectedPeriod = now.toLocaleString('en-US', { month: 'short' });
  }

  // Add this helper method to get the last 3 months data
  private getLastThreeMonthsRange(): { startDate: Date, endDate: Date } {
    const endDate = new Date();
    const startDate = new Date();
    startDate.setMonth(startDate.getMonth() - 3);
    return { startDate, endDate };
  }

  getActiveFilterCount(): number {
    return Object.values(this.messageFilters).filter(value => value).length;
  }

  exportToExcel(monthData: FilteredMessage) {
    // Prepare data for export
    const exportData = monthData.messages.map(message => ({
      TimeOfEntry: message.timestamp,
      Type: this.getMessageType(message.text),
      Counterparty: message.header.split('|')[0].trim(),
      Details: message.text.replace(/<[^>]*>/g, ''), // Remove any HTML tags
    }));

    // Create worksheet
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(exportData);

    // Create workbook
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Market Data');

    // Generate filename
    const fileName = this.generateExcelFileName(monthData);

    // Save file
    XLSX.writeFile(wb, fileName);
  }

  private getMessageType(text: string): string {
    if (text.startsWith('Trade:')) return 'JeraGM Trade';
    if (text.startsWith('Platts Financial Trade')) return 'Platts Financial';
    if (text.startsWith('Platts Physical Trade')) return 'Platts Physical';
    if (text.startsWith('Freight')) return 'Freight Market';
    return 'Trader Message';
  }

  private generateExcelFileName(monthData: FilteredMessage): string {
    const timestamp = new Date().toISOString().replace(/[:.]/g, '-');
    let prefix = 'market-data';
    
    if (this.searchOnlyMode) {
      prefix = 'search-results';
    } else if (this.activeSearchYear) {
      prefix = this.selectedPeriod ? 
        `${this.selectedPeriod}-${this.activeSearchYear}` : 
        `${this.activeSearchYear}`;
    }
    
    return `${prefix}-${timestamp}.xlsx`;
  }

  getLoadingMessage(): string {
    const loadingStates = [];
    
    if (this.isLoadingTrades) {
        loadingStates.push('market trades');
    }
    if (this.isLoadingCounterpartyTrades) {
        loadingStates.push('counterparty trades');
    }
    if (this.isLoadingFreightChat) {
        loadingStates.push('freight chat');
    }

    let baseMessage = '';
    if (loadingStates.length === 0) {
        return '';
    }
    if (loadingStates.length === 1) {
        baseMessage = `Loading ${loadingStates[0]}`;
    } else if (loadingStates.length === 2) {
        baseMessage = `Loading ${loadingStates[0]} and ${loadingStates[1]}`;
    } else {
        baseMessage = 'Loading all market data';
    }

    if (this.isRetrying) {
        return `${baseMessage}... (Retry attempt ${this.currentRetryAttempt}/${this.retryCount})`;
    }
    
    return `${baseMessage}...`;
  }
}