import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CounterpartyTrade } from './counterparty-trade.model';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CounterpartyTradeService {
  private apiUrl = `${environment.applicationApi}/messages/counterparty-trades`;

  constructor(private http: HttpClient) { }

  getCounterpartyTrades(startDate?: Date, endDate?: Date): Observable<CounterpartyTrade[]> {
    let params = new HttpParams();
    
    if (startDate) {
      params = params.set('startDate', startDate.toISOString());
    }
    if (endDate) {
      params = params.set('endDate', endDate.toISOString());
    }

    return this.http.get<CounterpartyTrade[]>(this.apiUrl, { params });
  }
} 