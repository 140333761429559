import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-graphical-timeline-dialog',
  template: `
    <div class="dialog-header">
      <div class="header-content">
        <h2 mat-dialog-title>Graphical Timeline View</h2>
        <span class="period-title">{{ data.title }}</span>
      </div>
      <button mat-icon-button (click)="close()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    
    <mat-dialog-content class="dialog-content">
      <div class="graphical-timeline">
        <div class="timeline-line"></div>
        <div class="timeline-events">
          <ng-container *ngFor="let message of data.messages">
            <div class="timeline-event"
                 [ngClass]="{
                     'message-event': !message.text.startsWith('Trade:') && 
                                    !message.text.startsWith('Platts Financial Trade') && 
                                    !message.text.startsWith('Platts Physical Trade') &&
                                    !message.text.startsWith('Freight'),
                     'trade-event': message.text.startsWith('Trade:'),
                     'platts-financial-event': message.text.startsWith('Platts Financial Trade'),
                     'platts-physical-event': message.text.startsWith('Platts Physical Trade'),
                     'freight-chat-event': message.text.startsWith('Freight')
                 }">
              <div class="event-content">
                <div class="event-timestamp">{{ message.timestamp }}</div>
                <div class="event-text">
                  <mat-icon class="entry-icon" [ngStyle]="{
                      'color': message.text.startsWith('Trade:') ? '#1976d2' : 
                               message.text.startsWith('Platts Financial Trade') ? '#fbc02d' :
                               message.text.startsWith('Platts Physical Trade') ? '#2e7d32' :
                               message.text.startsWith('Freight') ? '#ab47bc' : 'gray'
                  }">
                      {{message.text.startsWith('Trade:') ? 'swap_horiz' :
                         message.text.startsWith('Platts Financial Trade') ? 'trending_up' :
                         message.text.startsWith('Platts Physical Trade') ? 'local_shipping' :
                         message.text.startsWith('Freight') ? 'chat_bubble' : 'chat'}}
                  </mat-icon>
                  <div [innerHTML]="boldHeader(message.header)"></div>
                  <div class="message-text" [innerHTML]="message.text"></div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </mat-dialog-content>
  `,
  styleUrls: ['./graphical-timeline-dialog.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatIconModule,
    MatButtonModule
  ]
})
export class GraphicalTimelineDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<GraphicalTimelineDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { messages: any[], title: string }
  ) {
    console.log('Dialog data:', data); // Add this for debugging
  }

  close(): void {
    this.dialogRef.close();
  }

  boldHeader(header: string): string {
    if (!header) return '';
    const [boldPart, rest] = header.split(' | ');
    return rest ? `<b>${boldPart}</b> | ${rest}` : `<b>${boldPart}</b>`;
  }
} 